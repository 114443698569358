<template>

  <div class="form-elements">
    <div class="va-row">
      <div class="flex md12">
        <vuestic-widget>
          <form autocomplete="off">
            <!-- INSERIR NOME -->
            <div class="va-row">
              <div class="flex md12 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="empresa.nome"
                        id="simple-input"
                        required
                      />
                      <label
                        class="control-label"
                        for="simple-input"
                      >Nome completo
                      </label>
                      <span
                        class="alert-danger"
                        v-if="erros['nome']"
                      >{{erros['nome'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>
              <!-- INSERIR CNPJ -->
              <div class="flex md6 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <the-mask
                        :mask="['##.###.###/####-##']"
                        v-model="empresa.cnpj"
                        id="simple-input"
                        required
                      ></the-mask>
                      <label
                        class="control-label"
                        for="simple-input"
                      >CNPJ
                      </label>
                      <span
                        class="alert-danger"
                        v-if="erros['cnpj']"
                      >{{erros['cnpj'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- SELECIONAR ÁREA -->
              <div class="flex md6 xs12">
                <fieldset>
                  <multiselect
                    v-model="areasSelecionada"
                    placeholder="Áreas"
                    track-by="id"
                    label="nome"
                    :options="areas"
                    :multiple="true"
                  />
                  <span
                    class=" alert-danger"
                    v-if="erros['areas']"
                  >{{erros['areas'][0]}}</span>
                </fieldset>
              </div>

              <!-- INSERIR CIDADE -->
              <div class="flex md6 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">
                      <input
                        v-model="empresa.cidade"
                        id="simple-input"
                        required
                      />
                      <label
                        class="control-label"
                        for="simple-input"
                      >Cidade
                      </label>
                      <span
                        class="alert-danger"
                        v-if="erros['cidade']"
                      >
                        {{erros['cidade'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>

              <!-- SELECIONAR CEP -->
              <div class="flex md6 xs12">
                <fieldset>
                  <div class="form-group">
                    <div class="input-group">

                      <the-mask
                        id="simple-input"
                        v-model="empresa.cep"
                        :mask="['#####-###']"
                        required
                      />
                      <label
                        class="control-label"
                        for="simple-input"
                      >CEP
                      </label>
                      <span
                        class="alert-danger"
                        v-if="erros['cep']"
                      >{{erros['cep'][0]}}</span>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </form>
          <!-- MODAL -->
          <div class="butoes">
            <button
              class="btn btn-pale btn-micro mr-2"
              @click="$router.push({name: 'lista-empresas'})"
            >
              Voltar </button>
            <button
              @click="cadastrarEmpresa()"
              class="btn btn-primary btn-micro"
            >
              confirmar </button>
          </div>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  name: 'cadastrar-empresa',
  data() {
    return {
      areasSelecionada: [],
      areas: [],
      empresa: {
        nome: '',
        cnpj: '',
        cidade: '',
        cep: '',
        areas: [],
      },
      erros: [],
    };
  },
  created() {
    this.getAreas();
  },
  methods: {
    getAreas() {
      this.$axios.get('/api/area.json').then((res) => {
        this.areas = res.data;
      });
    },
    launchToast() {
      this.showToast(
        this.toastText,
        {
          icon: this.toastIcon,
          position: this.toastPosition,
          duration: this.toastDuration,
          fullWidth: this.isToastFullWidth,
        },
      );
    },
    pegarIdDosObjetos(objetos) {
      if (objetos.length < 1) return [];
      const arrayDeIds = new Set();
      objetos.forEach(objeto => arrayDeIds.add(objeto.id));
      return arrayDeIds;
    },
    cadastrarEmpresa() {
      this.empresa.areas = this.pegarIdDosObjetos(this.areasSelecionada);
      this.$axios.post('/api/empresa.json', this.empresa).then(() => {
        this.$swal({
          toast: true,
          position: 'top-end',
          showCancelButton: false,
          type: 'success',
          title: 'Cadastrado com Sucesso!',
          showConfirmButton: false,
          timer: 2000,
        });
        this.limparCampos();
      }).catch((err) => {
        this.erros = err.response.data;
      });
    },
    limparCampos() {
      this.empresa = {
        nome: '',
        cnpj: '',
        cidade: '',
        cep: '',
        areas: [],
      };
      this.areasSelecionada = [];
    },
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.butoes {
  display: flex;
  justify-content: flex-end;
}
.multiselect{
  margin-top: 0.08rem;
}
@media screen and (max-width: 767px) {
  .multiselect{
    margin-bottom: 1rem
  }
}
</style>
